import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/index",
    },
    {
        path: "/index", //app首页
        name: "index",
        component: () => import("../views/applets/index.vue"),
        redirect: "/applet/index/traceInfo",
        meta:{
            // 页面标题title
            title: '潢溪溯源'
        },
        children: [
            {
                path: "/applet/index/proInfo", //商品介绍
                name: "proInfo",
                component: () => import("../views/applets/proinfo.vue"),
                meta:{
                    // 页面标题title
                    title: '潢溪溯源'
                },
            },
            {
                path: "/applet/index/traceInfo", //溯源信息品介绍
                name: "traceInfo",
                component: () => import("../views/applets/traceinfo.vue"),
                meta:{
                    // 页面标题title
                    title: '潢溪溯源'
                },
            },
        ],
    },
    {
        path: "/applet/grow", //生长过程-app
        name: "grow",
        component: () => import("../views/applets/grow.vue"),
        meta:{
            // 页面标题title
            title: '潢溪溯源-生长过程'
        },
    },
    {
        path: "/applet/process", //加工仓储-app
        name: "process",
        component: () => import("../views/applets/process.vue"),
        meta:{
            // 页面标题title
            title: '潢溪溯源-加工仓储'
        },
    },
    {
        path: "/applet/message", //留言-app
        name: "message",
        component: () => import("../views/applets/message.vue"),
        meta:{
            // 页面标题title
            title: '潢溪溯源-留言'
        },
    },
    {
        path: "/applet/launch", //启动页-app
        name: "launch",
        component: () => import("../views/applets/launch.vue"),
    }
];


const router = new VueRouter({
    mode: "hash", //history
    base: process.env.BASE_URL,
    routes,
});

export default router;
