import Vue from "vue";
import VueAMap from "vue-amap";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import axios from "axios";
import "ant-design-vue/dist/antd.css"; // or 'ant-design-vue/dist/antd.less'
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
import "./assets/css/font/iconfont.css";
import "./assets/js/rem";
import "./assets/js/getDpr";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import qs from 'qs';


/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
})

Vue.use(Viewer);
Viewer.setDefaults({
    // zIndexInline: 2017,
    "inline": false,
    "button": true,
    "navbar": false,
    "title": false,
    "toolbar": true,
    "tooltip": false,
    "movable": false,
    "zoomable": false,
    "rotatable": false,
    "scalable": false,
    "transition": false,
    "fullscreen": false,
    "keyboard": false,
    "url": "data-source"
})
// Vue.use(preview);//查看大图
Vue.use(Antd);
// axios.defaults.baseURL = '/api';//接口地址
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
axios.defaults.baseURL = 'https://yun.sowreap.cn/api/';//接口地址
// axios.defaults.baseURL = '/api';//接口地址
import moment from "moment"; //时间格式化
moment.locale("zh-cn");
Vue.config.productionTip = false;

// 高德地图
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: "68e24161e29e01db887098e795fd1786",
    plugin: [
        "AMap.Autocomplete", //输入提示插件
        "AMap.PlaceSearch", //POI搜索插件
        "AMap.Scale", //右下角缩略图插件 比例尺
        "AMap.OverView", //地图鹰眼插件
        "AMap.ToolBar", //地图工具条
        "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
        "AMap.PolyEditor", //编辑 折线多，边形
        "AMap.CircleEditor", //圆形编辑器插件
        "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
    ],
    v: "1.4.4",
    uiVersion: "1.0",
});

new Vue({
    router,
    axios,
    render: (h) => h(App),
}).$mount("#app");

const setHtmlFontSize = () => {
    const htmlDom = document.getElementsByTagName('html')[0];
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (htmlWidth >= 750) {
        htmlWidth = 750;
    }
    if (htmlWidth <= 320) {
        htmlWidth = 320;
    }
    htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();